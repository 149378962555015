import React from 'react';

function Connected() {
  return (
    <div className="App">
      <header className="App-header">
        {/* later, it will redirect to the user page instead of this */}
        <h2 id="subtitle" className="text-center ease">You connected successfully!</h2>
      </header>
    </div>
  );
}

export default Connected;