import React from 'react';
import ActivityFeed from './ActivityFeed';
import ActivityMap from './ActivityMap';
import Profile from './Profile';
import Summit from './Summit';

import { withRouter } from "react-router";
import  { Link, Switch, Route } from 'react-router-dom';
import Cookies from 'js-cookie';
import logoImage from '../images/logo.png';
import * as Sentry from '@sentry/react';

class UserPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      user: {},
    };
  }

  async componentDidMount() {
    const userId = this.props.demo ? 'greg' : this.props.match.params.id;
    const apiHost = process.env.REACT_APP_USE_LOCAL_API ? 'http://localhost:3001' : 'https://hilly.run';

    // This means we were redirected from authentication
    if (window.location.hash === '#connected') {
      // Save the userId, this will redirect the user back to their page
      // expires 5 years from now
      Cookies.set('userId', userId, { expires: 1825 })
    }

    try {
      const getUserResponse = await fetch(`${apiHost}/api/users/${userId}`);
      const userResponseData = await getUserResponse.json();
      console.log("User Data:");
      console.log(userResponseData);

      const user = userResponseData.user;

      // todo, after filtering bad data, maybe log to sentry. Right now experimenting with data model..
      // const activities = userResponseData.activities.filter((activity) => activity.summits != undefined);
      // const activities = userResponseData.summits.


      // group summit completions by activity
      const activitiesMap = {};
      for (var summitCompletion of userResponseData.summitCompletions) {
        var id = summitCompletion.activityData.id;
        activitiesMap[id] = activitiesMap[id] || { activity: summitCompletion.activityData, summits: [] };
        activitiesMap[id].summits.push(summitCompletion.summitData);
      }

      const activities = Object.values(activitiesMap);

      console.log(activities)

      if (user === undefined) {
        this.setState({ isLoaded: true, error: 'user not found' });
        return;
      }

      // const totalSummitCount = activities.map((activity) => activity.summits.length).reduce((accumulator, currValue) => accumulator + currValue, 0);

      // create a hash of summit osm id -> { ...summitData, activities: [...]}
      const summitActivities = {}
      for (const activity of activities) {
        for (const summit of activity.summits) {
          summitActivities[summit.osm_id] = summitActivities[summit.osm_id] || {...summit};
          summitActivities[summit.osm_id].activities = summitActivities[summit.osm_id].activities || [];
          summitActivities[summit.osm_id].activities = summitActivities[summit.osm_id].activities.concat([activity]);
        }
      }

      this.setState({
        isLoaded: true,
        user: user,
        activities: activities,
        summitCompletions: userResponseData.summitCompletions,
        stats: userResponseData.stats,
      });
    } catch (error) {
      if (process.env.NODE_ENV === 'development') throw(error);
      Sentry.captureException(error);
      this.setState({
        isLoaded: true,
        error
      });
    }
  }

  renderBody() {
    const { user, activities, stats, summitCompletions } = this.state;

    if (activities.length === 0) {
      return (
        <div className="custom-card text-left">
          <div className="cardBody ">
            <div className="col-md-6 offset-md-3">
              <h5>Successfully connected to hilly.run!</h5>
              <p>This is the summit log for your Strava activities. Summits found in new activities will show up here.</p>
              <p>We're still working on processing old activities. Check back soon! Thanks, Greg </p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <Switch>
        <Route path={`/users/:id/map`}>
          <ActivityMap activities={activities} userId={user.athlete.id}/>
        </Route>
        <Route
          path="/users/:id/summits/:summitId"
          render={({ match }) => <Summit user={user} summitId={match.params.summitId} summitCompletions={summitCompletions}/> }
        />
        <Route path={`/users/:id`}>
          <div className="row">
            <div className="col-md-4 offset-md-1">
              <Profile user={user.athlete} stats={stats}/>
            </div>
            <div className="col-md-6">
              <ActivityFeed activities={activities} stats={stats} userId={this.props.match.params.id}/>
            </div>
          </div>
        </Route>
      </Switch>
    );
  }

  render() {
    const { error, isLoaded } = this.state;
    // todo fix this
    const mapTab = window.location.pathname.split('/').slice(-1)[0] === 'map';

    if (error) {
      return (
        <div className='flex-wrapper'>
          <div className="container">
            <div className="row">
              <img className="user-page-logo" src={logoImage} alt="hilly.run"/>
            </div>
            <h1 className="text-center">Sorry, an error occurred</h1>
          </div>
        </div>
      )
    } else if (!isLoaded) {
      return <div></div>;
    } else {
      return (
        <div className='flex-wrapper'>
          <div className="container">
            <div className="row">
              <img className="user-page-logo" src={logoImage} alt="hilly.run"/>
            </div>
            <div className="row">
              <div className="nav-row">
                <Link to={`/users/${this.props.match.params.id}`}>
                  <span className={`navItem ${mapTab ? '' : 'activeNav'}`}>
                    Home
                  </span>
                </Link>
                <Link to={`/users/${this.props.match.params.id}/map`}>
                  <span className={`navItem ${mapTab ? 'activeNav' : ''}`}>
                    Map
                  </span>
                </Link>
              </div>
            </div>
            { this.renderBody(this.state) }
          </div>
        </div>
      );
    }
  }
}

export default withRouter(UserPage);
