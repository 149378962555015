import React from 'react';
import Activity from './Activity';

function ActivityFeed(props) {
  // make this const. see below.
  let { activities, stats, userId } = props;

  // use a sort function that returns a new array. probably lodash does that.
  activities.sort((a, b) => {
    return new Date(b.activity.start_date) - new Date(a.activity.start_date);
  });

  return (
    activities.slice(0, 10).map(activity => (
      <Activity key={activity.activity.id} activity={activity} userId={userId} stats={stats}/>
    ))
  );
}

export default ActivityFeed;
