import React from 'react';
import { useHistory } from 'react-router-dom'

// import * as Sentry from '@sentry/react';

function Summit(props) {
  // fix this
  // given all summit comletions, filter out the ones for this summit
  const currentSummitCompletions = props.summitCompletions.filter((s) => {
    // and cover the edge case for Engagement hill (null)
    if (props.summitId === "null") return s.summitData.osm_id === null;

    return s.summitData.osm_id && s.summitData.osm_id.toString() === props.summitId;
  })

  const summit = currentSummitCompletions[0].summitData;

  const activities = summitActivities(currentSummitCompletions);
  let history = useHistory();

  return (
    <div className="row">
      <div className="col-md-10 offset-md-1">
        <button className='btn btn-link' onClick={() => history.goBack()}>
          <p style={{ color: 'black'}} className="pl-2">← back</p>
        </button>
        <div className="custom-card">
          <div className="cardBody">
          <div className="mb-4 text-left">
            <div className="clearfix">
              <h3 className="float-left">{ summit.name }</h3>
              <h5 className="pt-2 float-right">
                Total: {currentSummitCompletions.length}
              </h5>
            </div>
            <span className='summitHeightBigger'>{Math.floor(summit.ele * 3.28084)} ft.</span>
          </div>
          <div className="row">
            <div className="col-md-6 text-left">
              <div className="clearfix mb-2">
                {/* <span className='h5'>Your Activities</span> */}
              </div>
              { activities }
            </div>
            <div className="col-md-6">
              <img width="100%" alt='' src={staticMapUrl(summit)}></img>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function summitActivities(summitCompletions){
  return summitCompletions.map((completion) => {
    const date = new Date(completion.activityData.start_date).toDateString();

    return (
      <div className="text-left" key={completion.activityData.id}>
        <a target="_blank" rel="noopener noreferrer" href={`https://www.strava.com/activities/${completion.activityData.id}`}>
          {completion.activityData.name}
        </a>
        <span className="float-right fadedText">{ date }</span>
      </div>
    )
  })
}

function staticMapUrl(summitData) {
  const markerUrl = encodeURIComponent('https://s3.amazonaws.com/hilly.run/peak.png');
  const mapboxToken = "pk.eyJ1IjoiZ2tyYXRod29obCIsImEiOiJ5bXlHNXJJIn0.gjNMPWKFNso_Z5EmEjsFGA";

  const markers = `url-${markerUrl}(${summitData.lon},${summitData.lat})`;
  const staticMapUrl = `https://api.mapbox.com/styles/v1/gkrathwohl/ckj0wvdxh31uk19o14oz2kaih/static/${markers}/${summitData.lon},${summitData.lat},12/400x400?access_token=${mapboxToken}&attribution=false&logo=false`

  return staticMapUrl
}

export default Summit;
