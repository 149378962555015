import React from 'react';
import connectImage from '../images/connect_with_strava.png'
import Cookies from 'js-cookie'
import { Redirect } from "react-router-dom";
import descriptionImage from '../images/description.png'
import descriptionImage1 from '../images/description1.png'
import logoImage from '../images/logo.png'

function Home() {
  const apiHost = process.env.REACT_APP_USE_LOCAL_API ? 'http://localhost:3001' : 'https://hilly.run';

  const userId = Cookies.get('userId');
  if (userId !== undefined) {
    return <Redirect to={`/users/${userId}`} />;
  }

  return (
    <div className="App App-header">
      <div className="container">
        <div className="row">
          <img className="main-logo" src={logoImage} alt="hilly.run"/>
        </div>
        <div className="row">
          <a className="connect-button" href={`https://www.strava.com/oauth/authorize?client_id=3764&redirect_uri=${apiHost}/api/auth&response_type=code&scope=activity:read_all,activity:write&approval_prompt=auto`}>
            <img src={connectImage} alt="Connect with Strava"/>
          </a>
        </div>
          {/* <h2 id="subtitle" className="text-center ease">Find summits in your Strava activities</h2> */}
        <div className="row instructions">
          {/* <div className="col-md-4">
            <div>1. Bag some peaks</div>
          </div> */}
          <div className="col-md-6 instruction">
          <div className="instruction-text">Automatically tag summits on Strava</div>
            <img className="instruction-image" src={descriptionImage} alt="Automatically tag summits on Strava"/>
          </div>
          <div className="col-md-6 instruction">
            <div className="instruction-text">See summit logs on hilly.run</div>
            <img className="instruction-image" src={descriptionImage1} alt="See summit logs on hilly.run"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;