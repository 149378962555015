import React, { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf'
import peakImage from '../images/peak.png'
import 'mapbox-gl/dist/mapbox-gl.css';
import { useHistory } from "react-router-dom";

mapboxgl.accessToken = 'pk.eyJ1IjoiZ2tyYXRod29obCIsImEiOiJ5bXlHNXJJIn0.gjNMPWKFNso_Z5EmEjsFGA';

const ActivityMap = (props) => {
  let history = useHistory();
  const mapContainerRef = useRef(null);

  // const [lng, setLng] = useState(5);
  // const [lat, setLat] = useState(34);
  // const [zoom, setZoom] = useState(1.5);

  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/gkrathwohl/ckj0wvdxh31uk19o14oz2kaih',
      // style: 'mapbox://styles/mapbox/streets-v11',
      // center: [lng, lat],
      // zoom: zoom,
      // attributionControl: false,
    });

    const summits = props.activities.map((activity) => activity.summits).flat();
    let summitMap = {};
    for(var summit of summits) {
      summitMap[summit.id] = summit;
    }
    const features = Object.values(summitMap).map((summit) => {
      return {
        'type': 'Feature',
        // 'properties': {
        //     'description':
        //         '<strong>Truckeroo</strong><p><a href="http://www.truckeroodc.com/www/" target="_blank">Truckeroo</a> brings dozens of food trucks, live music, and games to half and M Street SE (across from Navy Yard Metro Station) today from 11:00 a.m. to 11:00 p.m.</p>',
        //     'icon': 'music'
        // },
        'properties': {
          'id': summit.osm_id,
          'description': summit.name,
        },
        'geometry': {
            'type': 'Point',
            'coordinates': [summit.lon, summit.lat]
        }
      }
    });

    const featureCollection = {
      'type': 'FeatureCollection',
      'features': features
    }
    const bbox = turf.bbox(featureCollection);

    map.fitBounds(
      [[bbox[0], bbox[1]],[bbox[2], bbox[3]]], { padding: 100, maxZoom: 12 },
    );

    map.on('load', function () {
      map.loadImage(peakImage,
        (error, image) => {
          if (error) throw error;
          map.addImage('peak', image);

          map.addSource('places', {
              'type': 'geojson',
              'data': featureCollection,
          });

          // Summit Icons
          map.addLayer({
              'id': 'places',
              'type': 'symbol',
              'source': 'places',
              'layout': {
                  'icon-image': 'peak',
                  'icon-size': 0.8,
                  'icon-allow-overlap': true,
              },
          });

          map.on('click', 'places', function (e) {
            const summitId = e.features[0].properties.id;
            console.log(summitId)
            history.push(`/users/${props.userId}/summits/${summitId}`);
          })

          map.on('mouseenter', 'places', function () {
            map.getCanvas().style.cursor = 'pointer';
          });

          // Change it back to a pointer when it leaves.
          map.on('mouseleave', 'places', function () {
            map.getCanvas().style.cursor = '';
          });

          // Sumit Labels
          map.addLayer({
            'id': 'places-labels',
            'type': 'symbol',
            'source': 'places',
            'layout': {
              'text-anchor': 'top',
              "text-size": {
                "stops": [
                        [0, 0],
                        [4, 0],
                        [4.1, 20],
                    ]
                },
                'text-allow-overlap': false,
                'text-field': [
                  'format',
                  ['get', 'description'],
                  {
                    'font-scale': 0.7,
                  },
                ],
                'text-radial-offset': 0.7,
            },
            'paint': {
              'text-color': '#5a4f45',
              "text-halo-color": "#ffffff",
              "text-halo-width": 1.3,
            }
        });
        }
      );
    });
    // Clean up on unmount
    return () => map.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="row">
      <div className="col-md-10 offset-md-1">
        <div className="custom-card">
          <div className='map-container' ref={mapContainerRef} />
        </div>
      </div>
    </div>
  );
};

export default ActivityMap;