import React from 'react';
import peakImage from '../images/peak.png';
import  { Link } from 'react-router-dom';

function getStaticMapUrl(activity) {
  const markerUrl = encodeURIComponent('https://s3.amazonaws.com/hilly.run/peak.png');
  const mapboxToken = "pk.eyJ1IjoiZ2tyYXRod29obCIsImEiOiJ5bXlHNXJJIn0.gjNMPWKFNso_Z5EmEjsFGA";

  const markers = activity.summits.map((summit) => `url-${markerUrl}(${summit.lon},${summit.lat})`).join(',');

  const path = `path-5+fff-1(${encodeURIComponent(activity.activity.summary_polyline)}),path-2+f44-1(${encodeURIComponent(activity.activity.summary_polyline)})`;

  const staticMapUrl = `https://api.mapbox.com/styles/v1/gkrathwohl/ckj0wvdxh31uk19o14oz2kaih/static/${path},${markers}/auto/600x400?access_token=${mapboxToken}&attribution=false&logo=false`

  return staticMapUrl
}

function Activity(props) {
  const { activity, userId } = props;

  // don't just write a bunch of code then throw iti n a function to clean it up.
  // well maybe. 
  // but now this function knows all about the shape of activity..
  // what if it was 
  const staticMapUrl = getStaticMapUrl(activity);
  const date = new Date(activity.activity.start_date).toDateString()

  return (
    <div className="custom-card text-left">
      <div className="cardBody">
        <div>
          <h5 className="activityTitle" key={activity.activity.SK}>
            <a target="_blank" rel="noopener noreferrer" href={`https://www.strava.com/activities/${activity.activity.id}`}>
              {activity.activity.name}
            </a>
          </h5>
          <div className="activityDate">{date}</div>
        </div>
        <div className='summitList'>
          {activity.summits.map(summit => (
            <div key={summit.osm_id} className='summitLabel'>
              <img alt='' src={peakImage}></img>
              <span>{summit.name}</span>
              <span className='summitHeight'>{Math.floor(summit.ele * 3.28084)} ft.</span>
              <Link to={`/users/${userId}/summits/${summit.osm_id}`}>
                <span className='viewSummitButton'>view summit</span>
              </Link>
              {/* <span>{stats.summitActivities[summit.id].length} total</span> */}
            </div>
          ))}
        </div>
        <img width="100%" alt='' src={staticMapUrl}></img>
      </div>
    </div>
  );
}

export default Activity;