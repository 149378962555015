import React from 'react';

function Profile (props) {
  const { user, stats } = props;

  return (
    <div className="profile custom-card">
      <img alt={`${user.firstname} ${user.lastname}`} src={user.profile}></img>
      <h1 className="profile__header"><a target="_blank" rel="noopener noreferrer" href={`https://www.strava.com/athletes/${user.id}`}>{`${user.firstname} ${user.lastname}`}</a></h1>
      <p className="profile__location">{`${user.city}, ${user.state}`}</p>
      {/* <p className="profile__summits">2020</p>
      <p className="profile__summits">69 summits</p>
      <p className="profile__summits">All-Time</p> */}
      <p className="profile__summits">{stats.totalCount} summits</p>
    </div>
  )
}

export default Profile;