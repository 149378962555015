import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  // Link,
  // useRouteMatch,
  // useParams
} from "react-router-dom";
import UserPage from './components/UserPage';
import Home from './components/Home';
import Connected from './components/Connected';

console.log(`environment: ${process.env.NODE_ENV}`)

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/connected" component={Connected} />
          <Route path="/greg">
            <UserPage demo={true} />
          </Route>
          <Route path="/users/:id" component={UserPage} />
          <Route exact path="/" component={Home} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
